import ZeroFee from "../assets/zerofeevirtualcard.svg";
import CrossBorderImage from "../assets/cross boarder 1.svg";
import MultiCurrencyImage from "../assets/multicurrency.svg";
import PayAirtimeAndBillImage from "../assets/payairtimeandbills.svg";
import BuySellDollarImage from "../assets/buyselldollar.svg";


let Constants = {
    SUBMIT: "/submit",
    LEADERBOARD: "/referral-leaderboard",
    REFERRER: "/referrer/detail",
    LEFT_RIGHT: [
        {
            img: ZeroFee,
            heroText: ["Zero Fee Virtual Dollar", "Card 💳"],
            subText: ["Receive a FREE Virtual Card and $20 USD as a welcome",
                      "gift! when we go live. You will experience the power of",
                      "Zyggy and the way we handle card transactions."],
            ctaButtonText: "Join the waitlist",
            imgLeft: true,
        },
        {
            img: CrossBorderImage,
            heroText: [`Cross Border payment`, ` like never before `],
            subText: ["👪 Zero fees - connect with friends and family or business back home easily.",
                        `✅ Money is delivered to bank account or wallet within minutes. Better exchange rate!`],
            ctaButtonText: "Join the waitlist",
            imgLeft: false,
        },
        {
            img: MultiCurrencyImage,
            heroText: ["Open a multi-currency ","wallet within mintues"],
            subText: ["Accept International payments and grow your business with" , "our UK, US and Canadian currency wallets."],
            ctaButtonText: "Join the waitlist",
            imgLeft: true,
        },
        {
            img: BuySellDollarImage,
            heroText: ["Buy and Sell your dollars", "within minutes at your", "own exchange rate"],
            subText: ["💵 Get US dollars within minutes at your own exchange", "rate using our Peer2Peer feature"],
            ctaButtonText: "Join the waitlist",
            imgLeft: false,
        },
        {
            img: PayAirtimeAndBillImage,
            heroText: ["Pay airtime and bill" , "payments on the go!"],
            subText: ["Be one of the first to access Zyggy and you get free" ,"₦‎5000 on signup"],
            ctaButtonText: "Join the waitlist",
            imgLeft: true,
        }
    ]

}

export default Constants;