import PositionStand from "./PositionStand";


let Top3PositionListComponent = ({data}) => {
    return <>
        <div className="mb-[37px] flex justify-center relative -top-20">
            {
                data ? (
                    <div className="flex flex-row items-end gap-10">
                        
                        <PositionStand {...data[1]} position={2}></PositionStand>
                        <PositionStand {...data[0]} position={1} ></PositionStand>
                        <PositionStand {...data[2]} position={3}></PositionStand>
                    </div>
                )
                : ""
            }
        </div>
    </>
}


export default Top3PositionListComponent;