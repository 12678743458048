

let InputComponent2 = (props) => {
    let {type, placeholder, value,id, onChange} = props;
    return <>
        <div className="text-left">
            <input id={id} className="p-3 gap-4 w-full rounded-md zyggy-font" onChange={onChange} type={type} placeholder={placeholder} ></input>
        </div>
    </>
}


export default InputComponent2;