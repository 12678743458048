import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Constants from "../constants/Constants";
import ReferralService from "../api/services/ReferralService";
const useForm = () => {
    const navigate = useNavigate();

    const submitForm = async (userData) => {
        let _sub;
        console.log("===================");
        console.log(userData)
        console.log("===================");
        
        if (userData.referralCode) {
            _sub = await ReferralService.submitWithReferral(userData);
        }
        else{
            _sub = await ReferralService.submit(userData);

        }
        if(_sub.type == "error"){
            toast.error(_sub.msg);
        }
        if(_sub.type == "success"){
            toast.success(_sub.msg)
        }
        console.log(_sub);
        return _sub;

    }

    useEffect(() => {
        return () => {
        };
    }, []);

    return {
        submitForm,
    };
};

export default useForm;
