import { useState, React, createContext } from "react";


export const AccountContext = createContext();

// Create an exportable consumer that can be injected into components
export const AccountConsumer = AccountContext.Consumer
// Create the provider using a traditional React.Component class

const AccountProvider = ({children}) => {
    let [user, setUser] = useState([]);
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [mobileNumber, setMobileNumber] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [referralCode, setReferralCode] = useState("");
    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <AccountContext.Provider value={{
                user, setUser,
                firstName, setFirstName,
                lastName, setLastName,
                mobileNumber, setMobileNumber,
                emailAddress, setEmailAddress,
                referralCode, setReferralCode
            }}>
            {children}
        </AccountContext.Provider>
    )
}
export default AccountProvider
