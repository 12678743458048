
import { NavLink } from "react-router-dom";
import ZyggyLogo from "../assets/zyggy-white.svg";

let TopComponent = () => {
    return <>
        <div className="flex zyggy-font flex-col md:flex-row p-4 pt-[33px] md:px-[72.81px] bg-transparent mb-5 justify-between ">
            <img className="h-9 text-white " src={ZyggyLogo}></img>
            <div className="flex justify-center text-base mt-4 md:mt-0 pr-6 gap-6">
                <a className="font-regular text-white hover:underline hover:underline-offset-2 text-base " href="https://zyggy.co">Zyggy Home</a>
                <NavLink to={"/waitlist"} className="font-regular text-white hover:underline hover:underline-offset-2 text-base " >Zyggy Waitlist </NavLink>
                <NavLink to={"/waitlist/leaderboard"} className="font-regular text-white hover:underline hover:underline-offset-2 text-base " >Waitlist Leaderboard </NavLink>
                
            </div>
        </div>
    </>
}


export default TopComponent;