


let PositionComponent = ({ position, referralCode, referrals, firstName, lastName }) => {
    return <>
        <div className="rounded-xl zyggy-font bg-zyggy-light-blue-3 p-4 mb-[20px] items-center flex flex-row justify-between">
            <div className="rounded-[50px] bg-zyggy-default px-4 py-2">
                <p className="font-bold text-white text-[20px]">{position + 1}th</p>
            </div>
            <div className="text-white text-2xl">
                <p>{firstName} {lastName}</p>
            </div>
            <div className="text-zyggy-green text-2xl">
                <p>{referrals * 10}</p>
            </div>
        </div>
    </>
}

export default PositionComponent;