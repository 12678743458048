import PositionComponent from "./PositionComponent";


let PositionListComponent = ({ data }) => {
    return <>
        <div className="">
            <div className="grid grid-cols-3 font-bold text-[20px] mb-[16px] text-zyggy-dark-blue-2">
                <div className="text-left">
                    <p >Position</p>
                </div>
                <p >Users</p>
                <div className="text-right">
                    <p >Points</p>
                </div>

            </div>
            {
                data ? data.map((user, index) => {
                    let position = index;
                    if (position >= 3 && position < 10) {
                        return <PositionComponent position={position} {...user}></PositionComponent>
                    }
                }) : ""
            }
        </div>
    </>
}

export default PositionListComponent;