import { useEffect, useState } from "react";
import ReferralService from "../api/services/ReferralService";
import TopComponent from "../components/TopComponent";
import LeaderComponent from "../components/LeaderComponent";

import RightPhoneImg from "../assets/rightphone.png";
import MainBgImg from "../assets/mainbgimg.svg";
import CicleMask from "../assets/circle mask.svg"
import LinePathMask from "../assets/linepathmask.svg";
import CircleHighlight from "../assets/circleHIghlight.svg";
import LineRayMask from "../assets/line backgroundMask group.svg"
import {
    toast
} from "react-toastify";
import PositionListComponent from "../components/PositionListComponent";
import Top3PositionListComponent from "../components/Top3PositionListComponent";
let LeaderBoard = () => {
    let [leaderBoard, setLeaderBoard] = useState([]);
    let [total, setTotal] = useState(0);
    let fetchLeaderBoard = async () => {
        try {
            let users = await ReferralService.fetchLeaderBoard();
            if (users.type == "success") {
                let sorted = users.data.leaders.sort((a, b) => b.referrals - a.referrals);
                console.log(sorted);
                setLeaderBoard(sorted);
                setTotal(users.data.total);
            }
            else {
                toast.error(users.msg);
            }
        }
        catch (e) {
            console.log(e);
            toast.error(e);
        }

    }

    useEffect(() => {
        console.log(leaderBoard);
        if (!leaderBoard.length > 0) {
            fetchLeaderBoard();
        }
    }, [leaderBoard]);

    return <>
        <div className="h-screen w-screen">
            <div className=" md:block" style={{ background: `url('${MainBgImg}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div className="" style={{ background: `url('${LinePathMask}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div className="h-full" style={{ background: `url('${CicleMask}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                        <div>
                            <TopComponent></TopComponent>
                        </div>
                        <div className="px-[162px] zyggy-font">
                            {/*top header*/}
                            <div className="flex justify-center">
                                <div className="bg-zyggy-dark-blue p-[24.56px] pb-0 rounded-t-[45.52px]">
                                    <div className="border border-white rounded-full bg-zyggy-nav-purple p-[30px]">
                                        <p className="text-zyggy-default font-bold text-[40px]">Zyggy Leaderboard</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-zyggy-dark-blue rounded-[50px] ">
                                <div className="z-10 px-[41.5px] py-[45px]" style={{ background: `url('${LineRayMask}')`, backgroundPosition: "top center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                                    
                                    <div >
                                        <div className="bg-zyggy-nav-purple px-6 py-6 flex flex-row justify-between items-center rounded-[18px] text-white">
                                            <p className="font-regular text-2xl">🚀 Top 10 Zyggy Leaderboard to <span className="font-bold">$20🤑</span></p>
                                            <div className="rounded-[50px] py-[8px] bg-zyggy-green text-xl flex flex-row px-4">
                                                <img></img>
                                                <p><span className="font-bold">{total}</span> users so far!!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            leaderBoard.length > 0 ? <Top3PositionListComponent data={leaderBoard}></Top3PositionListComponent>
                                                : ""
                                        }
                                    </div>
                                    <div>
                                        {leaderBoard.length > 0 ? <PositionListComponent data={leaderBoard}></PositionListComponent>
                                            : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>

                    </div>
                </div>
            </div>
        </div>
        {/*<div className="flex flex-col justify-center items-center">
                <p className="font-bold mb-2">Top 10 Zyggy LeaderBoard to $20</p>

                <p className="font-bold mb-3">{total} users so far!!</p>
                <div className="border p-4 flex-col flex">
                    {leaderBoard.length > 0 ? leaderBoard.map((leader, index) => {
                        let postion = index + 1;
                        return <LeaderComponent position={postion} {...leader}></LeaderComponent>
                    }) : ""}
                </div>
            </div>
                */}

    </>
}

export default LeaderBoard;