
import { useContext, useEffect, useState } from "react";

import InputComponent from "../components/InputComponent";

import ModalRootComponent from "../components/ModalRootComponent";
import { AccountContext } from "../context/AccountContext";
import ButtonComponent from "../components/ButtonComponent";
import { toast } from "react-toastify";
import useForm
    from "../hooks/useForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopComponent from "../components/TopComponent";
import RocketCard from "../assets/rocketcard.svg";
import Constants from "../constants/Constants";
import LeftRightComponent from "../components/LeftRightComponent";
import JoinBottomImg from "../assets/joinwaitbottomimage.svg";
import ZyggyLogo from "../assets/Zyggy+Logo02.png";
import ZyggyBottomLogo from "../assets/zyggy-bottom0vector.svg";
import InputComponent2 from "../components/InputComponent2";
import LeftPhoneImg from "../assets/iphoneLeft.png";
import RightPhoneImg from "../assets/rightphone.png";
import MainBgImg from "../assets/mainbgimg.svg";
import CicleMask from "../assets/circle mask.svg"
import LinePathMask from "../assets/linepathmask.svg";
import CircleHighlight from "../assets/circleHIghlight.svg";
let WaitListForm = ({ match, params }) => {
    //console.log(this.props.match.params);

    let { emailAddress, setEmailAddress } = useContext(AccountContext);
    let { lastName, setLastName } = useContext(AccountContext);
    let { firstName, setFirstName } = useContext(AccountContext);
    let { mobileNumber, setMobileNumber } = useContext(AccountContext);
    let { referralCode, setReferralCode } = useContext(AccountContext);
    let [isSubmitted, setIsSubmitted] = useState(false);
    let [userReferralCode, setUserReferralCode] = useState("");
    let { referrer } = useParams()
    if (referrer) {
        setReferralCode(referrer);

    }
    useEffect(() => {
        document.getElementById("referralCode").value = referrer ? referrer : "";
    }, [])
    //console.log(.url);
    let { submitForm } = useForm();
    let handleSubmit = async () => {
        console.log(";;;;;;;;;");
        if (!emailAddress || emailAddress == "") {
            toast.error("Email address needed");
            return;
        }
        let _s = await submitForm({
            emailAddress: emailAddress,
            lastName: lastName,
            firstName: firstName,
            mobileNumber: mobileNumber,
            referralCode: referralCode
        })

        if (_s) {
            if (_s.type == "success") {
                setUserReferralCode(_s.data.referralCode);
            }

        }
    }
    return <>
        <div className="w-screen zyggy-font from-zyggy-light to-zyggy-default h-screen">
            <div className="md:block hidden" style={{ background: `url('${MainBgImg}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div style={{ background: `url('${LinePathMask}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div style={{ background: `url('${CicleMask}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                        <div style={{ background: `url('${LeftPhoneImg}')`, backgroundPosition: "bottom left", backgroundRepeat: "no-repeat" }}>
                            <div style={{ background: `url('${RightPhoneImg}')`, backgroundPosition: "bottom right", backgroundRepeat: "no-repeat" }}>
                                <TopComponent></TopComponent>
                                <div className="p-6 text-white">
                                    <div className="flex justify-center">
                                        <img className="absolute items-center h-20 w-58" src={CircleHighlight}></img>
                                        <p className="font-bold zyggy-font text-6xl mb-6">Banking without borders !</p>
                                    </div>
                                    
                                    <p className="text-2xl">Be one of the first to access Zyggy <br></br> and get a free virtual card with free $20 🤑</p>
                                </div>
                                <div href="" className="flex justify-center">
                                    <div className="p-10 flex-col space-y-6 gap-6  ">
                                        <div  id="waitlistForm1" className="flex gap-4">
                                            <InputComponent2 onChange={(e) => setFirstName(e.target.value)} placeholder="First name" type="text"></InputComponent2>
                                            <InputComponent2 onChange={(e) => setLastName(e.target.value)} placeholder="Last name" type="text"></InputComponent2>
                                        </div>
                                        <div className="text-left">
                                            <InputComponent2 onChange={(e) => setEmailAddress(e.target.value)} placeholder="Email address (required)"></InputComponent2>
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="text-left" >
                                                <InputComponent2 onChange={(e) => setMobileNumber(e.target.value)} placeholder="Mobile number"></InputComponent2>
                                            </div>
                                            <div className="text-left">
                                                <InputComponent2 id="referralCode" onChange={(e) => (setReferralCode(e.target.value), e.target.value = e.target.value)} value={referralCode} placeholder="Referral code"></InputComponent2>
                                            </div>
                                        </div>
                                        <ButtonComponent onClick={() => handleSubmit()} color={"bg-zyggy-green"} text="Join the waitlist"></ButtonComponent>
                                        <div className="p-4">
                                            {userReferralCode ? <div>
                                                <p>Your referral code is <span className="font-bold">{userReferralCode}</span></p>
                                                <a className="font-bold underline underline-offset-1" href={`https://join.zyggy.co/waitlist/referralCode/${userReferralCode}`}>{`https://zyggy.vercel.app/waitlist/referralCode/${userReferralCode}`}</a>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden" style={{ background: `url('${MainBgImg}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <div style={{ background: `url('${LinePathMask}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                    <div style={{ background: `url('${CicleMask}')`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                        <div style={{ background: `url('${LeftPhoneImg}')`, backgroundPosition: "bottom left", backgroundRepeat: "no-repeat" }}>
                                <TopComponent></TopComponent>
                                <div className="p-6 text-white">
                                    <div className="flex justify-center">
                                        <img className="absolute items-center h-20 w-58" src={CircleHighlight}></img>
                                        <p className="font-bold zyggy-font text-6xl mb-6">Banking without borders !</p>
                                    </div>
                                    
                                    <p className="text-2xl">Be one of the first to access Zyggy <br></br> and get a free virtual card with free $20 🤑</p>
                                </div>
                                <div className="flex justify-center">
                                    <div className="p-10 flex-col space-y-6 gap-6  ">
                                        <div className="flex gap-4">
                                            <InputComponent2 onChange={(e) => setFirstName(e.target.value)} placeholder="First name" type="text"></InputComponent2>
                                            <InputComponent2 onChange={(e) => setLastName(e.target.value)} placeholder="Last name" type="text"></InputComponent2>
                                        </div>
                                        <div className="text-left">
                                            <InputComponent2 onChange={(e) => setEmailAddress(e.target.value)} placeholder="Email address (required)"></InputComponent2>
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="text-left" >
                                                <InputComponent2 onChange={(e) => setMobileNumber(e.target.value)} placeholder="Mobile number"></InputComponent2>
                                            </div>
                                            <div className="text-left">
                                                <InputComponent2 id="referralCode" onChange={(e) => (setReferralCode(e.target.value), e.target.value = e.target.value)} value={referralCode} placeholder="Referral code"></InputComponent2>
                                            </div>
                                        </div>
                                        <ButtonComponent onClick={() => handleSubmit()} color={"bg-zyggy-green"} text="Join the waitlist"></ButtonComponent>
                                        <div className="p-4">
                                            {userReferralCode ? <div>
                                                <p>Your referral code is <span className="font-bold">{userReferralCode}</span></p>
                                                <a className="font-bold underline underline-offset-1" href={`https://join.zyggy.co/waitlist/referralCode/${userReferralCode}`}>{`https://zyggy.vercel.app/waitlist/referralCode/${userReferralCode}`}</a>
                                            </div> : ""}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-[83.07px] flex-col justify-center items-center">
                <div className="flex justify-center">
                    <img src={RocketCard}></img>

                </div>
                <p className="text-zyggy-dark-blue font-bold text-[40px]">Say goodbye to boundaries and
                    <br></br>embrace a world without borders with
                    <br></br> Zyggy Cross-Border Payments.
                </p>
            </div>
            <div className="flex flex-col gap-20 pt-[118.77px] pb-[84.54px]" style={{ backgroundColor: "#F1F4FF" }}>
                {
                    Constants.LEFT_RIGHT ? Constants.LEFT_RIGHT.map(comp => {
                        return <LeftRightComponent {...comp}></LeftRightComponent>
                    }) :
                        ""
                }
            </div>
            <div className="flex justify-center flex-col gap-[110px] pt-[89.45px] items-center md:flex-row mb-[88.5px]">
                <p className="text-[60px] font-extrabold text-zyggy-default">
                    Giveaway
                    <br></br>
                    when we
                    <br></br>
                    go live 🎁
                </p>
                <p className="text-[24px] text-zyggy-dark-blue font-semibold text-left leading-10">
                    🤑 Get $20 free on signup
                    <br></br>
                    🛍️ Amazon Shopping Vouchers
                    <br></br>
                    🎧 Apple Music Vouchers
                    <br></br>
                    🎵 Spotify Vouchers
                    <br></br>
                    👚 Asos Vouchers
                    <br></br>
                    And many more!
                </p>

            </div>
            <div className="flex justify-center mb-[80.77px]">
                <div className="flex flex-col py-[53.57px] md:px-[57.24px] md:flex-row justify-center rounded-[52px] bg-zyggy-dark-blue">
                    <div className="">
                        <p className="text-zyggy-green font-bold text-[40px]">Join the waitlist</p>
                        <div className="">
                            <div className="p-10 flex-col space-y-6 gap-6  ">
                                <div className="flex gap-4">
                                    <InputComponent2 onChange={(e) => setFirstName(e.target.value)} placeholder="First name" type="text"></InputComponent2>
                                    <InputComponent2 onChange={(e) => setLastName(e.target.value)} placeholder="Last name" type="text"></InputComponent2>
                                </div>
                                <div className="text-left">
                                    <InputComponent2 onChange={(e) => setEmailAddress(e.target.value)} placeholder="Email address (required)"></InputComponent2>
                                </div>
                                <div className="flex gap-4">
                                    <div className="text-left" >
                                        <InputComponent2 onChange={(e) => setMobileNumber(e.target.value)} placeholder="Mobile number"></InputComponent2>
                                    </div>
                                    <div className="text-left">
                                        <InputComponent2 id="referralCode" onChange={(e) => (setReferralCode(e.target.value), e.target.value = e.target.value)} value={referralCode} placeholder="Referral code"></InputComponent2>
                                    </div>
                                </div>
                                <ButtonComponent onClick={() => handleSubmit()} color={"bg-zyggy-green"} text="Join the waitlist"></ButtonComponent>
                                <div className="p-4">
                                    {userReferralCode ? <div>
                                        <p>Your referral code is <span className="font-bold">{userReferralCode}</span></p>
                                        <a className="font-bold underline underline-offset-1" href={`https://zyggy.vercel.app/waitlist/referralCode/${userReferralCode}`}>{`https://zyggy.vercel.app/waitlist/referralCode/${userReferralCode}`}</a>
                                    </div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img src={JoinBottomImg}></img>

                    </div>
                </div>
            </div>
            <div className="flex justify-center flex-col items-center">
                <div className="flex flex-row justify-around items-center mb-4">
                    <img className="h-9" src={ZyggyBottomLogo}></img>
                </div>
                <div className="flex flex-row gap-4">
                    <Link to="https://zyggy.co/terms-of-service-1">
                        <p className="underline underline-offset-2 text-underline">Terms of Service</p>
                    </Link>
                    <Link to="https://zyggy.co/privacy-policy">
                        <p className="underline underline-offset-2 text-underline">Privacy policy</p>
                    </Link>
                </div>
            </div>
        </div>
    </>
}


export default WaitListForm;