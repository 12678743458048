


let ButtonComponent = ({text, onClick, color}) => {
    return <>
        <div onClick={onClick} className={`${color ? color : ""} hover:cursor-pointer rounded-full hover:drop-shadow-lg text-white font-bold p-4`}>
            {text}
        </div>
    </>
}

export default ButtonComponent;