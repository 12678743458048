
import { toast } from "react-toastify";
import axios from "../axios";

import authHeader from "./auth-header";


let RequestService = async (url, post, data, auth) => {
    let opt = {
        method: post ? "post" : "get",
        headers: {
            "Authorization": auth ? `Bearer ${authHeader()}` : ""
        }
    }
    if(post) opt.body = JSON.stringify(data);

    try {
        console.log(opt.method);
        let _req = await axios[opt.method](url, data,opt);
        console.log(_req.data);
        return _req.data;
    }
    catch (e) {
        console.log(e);
        toast.error("There seems to be a network connection issue");

    }


}

export default RequestService