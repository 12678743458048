import StarImg from "../assets/star.svg";
import FemaleMemoji from "../assets/female_memoji.svg";


let PositionStand = ({ position, firstName, lastName, referrals, }) => {
    return <>
        <div className="zyggy-font flex flex-col ">
            <span className="relative flex flex-col justify-center place-items-center top-[128px] ">
                <img className="circle justify-center border-8 z-10 border-zyggy-light-blue rounded-full" src={FemaleMemoji}></img>
                <div className="flex justify-center relative -top-8 z-20">
                    <div className="bg-zyggy-default gap-2 flex justify-center text-center items-center flex-row rounded-[50px] p-[10.5px]">
                        <img src={StarImg}>
                        </img>
                        <div className="text-base font-bold text-white">
                            {position == 1 ? <p>{position}st</p> : ""}
                            {position == 2 ? <p>{position}nd</p> : ""}
                            {position == 3 ? <p>{position}rd</p> : ""}
                        </div>

                    </div>
                </div>
            </span>



            <div className={`bg-zyggy-light-blue p-4 ${position == 1 ? "pt-36" : "pt-28 "} zyggy-font flex-col  rounded-[16px] text-white`}>
                <div className={`${position == 1 ? "mb-11" : "" } ${position == 2 ? "mb-7" : "" } ${position == 3 ? "pmb-4" : "" } `}>
                    <p className="font-bold text-[30px]">{firstName} {lastName}</p>
                </div>
                <div className="rounded-[8px] px-[94px] py-[16px] text-zyggy-green bg-zyggy-dark-blue">
                    <p className="font-bold text-[32px]">{referrals * 10}</p>
                </div>
            </div>
        </div>
    </>
}

export default PositionStand;