import Constants from "../../constants/Constants";
import RequestService from "./RequestService";



let ReferralService =  {
    submit : async (data) => {
        let url = Constants.SUBMIT;
        let req = await RequestService(url, true, data, false);
        console.log(req);
        return req;
    },
    submitWithReferral : async (data) => {
        let url = Constants.SUBMIT;
        let req = await RequestService(`${url}/${data.referralCode}`, true, data, false);
        console.log(req);
        return req;
    },
    
    fetchReferrer : async (referralCode) => {
        let url = Constants.REFERRER;
        let req = await RequestService(`${url}/${referralCode}`, false, false,false);
        return req;
    },
    fetchLeaderBoard : async () => {
        let url = Constants.LEADERBOARD;
        let req = await RequestService(url,false, false,false);
        return req;
    }
}

export default ReferralService;