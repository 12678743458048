

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes
} from "react-router-dom";
import AccountLayout from "../layouts/AccountLayout";
import Layout from "../layouts/Layout";
import WaitListForm from "../pages/WaitListForm";
import Error404 from "../pages/error404";
import Home from "../pages/Home";
import LeaderBoard from "../pages/LeaderBoard";


const homeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        
        <Route element={<AccountLayout />}>
        <Route path="/" element={<WaitListForm />}></Route>
          <Route path="waitlist" element={<WaitListForm />}></Route>
          <Route exact path="waitlist/referralCode/:referrer"  element={<WaitListForm/>}/>
          <Route path="waitlist/leaderboard" element={<LeaderBoard></LeaderBoard>} />
        </Route>
        <Route path="*" element={<Error404 />}></Route>
      </Route>

    </Route>

  )
);


export default homeRouter;
