import { Link } from "react-router-dom"
import ButtonComponent from "./ButtonComponent"
import AnchorLink from 'react-anchor-link-smooth-scroll'

let LeftRightComponent = ({ img, heroText, subText, ctaButtonText, imgLeft }) => {
    return <>
        <div className="flex flex-col px-8 md:flex-row justify-between items-center gap-20 md:px-[153.98px]">

            {imgLeft ?
                <div>
                    <img src={img}></img>
                </div>
                : ""}
            <div className="flex flex-col text-left justify-start  items-left">
                <p className="font-bold text-[40px] text-left text-zyggy-dark-blue">{heroText ? heroText.map(text => {
                    return <p>{text}</p>
                }) : ""}</p>
                <p className="text-left text-base text-zyggy-grey-1 mt-6 font-regular">{subText ? subText.map(text => {
                    return <p>{text}</p>
                }) : ""}</p>
                <br></br>
                <div className="flex">
                    <AnchorLink offset={100} href="#waitlistForm1">
                        <ButtonComponent color={"bg-zyggy-default"} text={ctaButtonText}></ButtonComponent>
                    </AnchorLink>
                </div>

            </div>
            {!imgLeft ?
                <div>
                    <img src={img}></img>
                </div>
                : ""}
        </div>
    </>

}


export default LeftRightComponent